import React, { useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@formio/react';
import DropDown from '../../assets/img/dropdown.svg';
import BlackCheckCircle from '../../assets/img/checkedRadioBlack.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import BillingIcon from '../../assets/img/PaymentBlack.svg';
import DownloadIcon from '../../assets/img/DownloadBlack.svg';
// import { useShowDigitalScoring } from 'hooks/profile';
import './ModalStyle.css';
const BillingInformationCard = ({ payment, handleSubmission, submittedRefund }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);

  let formioRef;

  const setForm = (ref) => {
    formioRef = ref;
  };

  console.log('refundForm', payment);

  const refundForm = {
    display: 'form',
    components: [
      {
        label: 'Select the sport event you want refund for below',
        optionsLabelPosition: 'right',
        tableView: false,
        values: payment.sport_event
          .filter((el, id) => el.is_team_sport === 0)
          .map((event) => ({
            label: `${event.age_group} ${event.sport} ${
              event.name.split('-')[5] ? event.name.split('-')[5] : ''
            }`,
            value: `${payment.invoice},${event.name},${event.bank_refund},${event.credit_refund}`,
          })),
        validate: {
          required: true,
          minSelectedCount: 1,
        },
        key: 'sport_event',
        type: 'radio',
        input: true,
      },
      {
        label: 'Where do you want refund to credit?',
        optionsLabelPosition: 'right',
        inline: false,
        tableView: false,
        values: [
          {
            label: 'SFA Credit',
            value: 'sfaCredit',
            shortcut: '',
          },
        ],
        validate: {
          required: true,
        },
        customConditional: 'show = (data.sport_event.split(",")[2] === \'0\')',
        key: 'mode',
        type: 'radio',
        input: true,
      },
      {
        label: 'Where do you want refund to credit?',
        optionsLabelPosition: 'right',
        inline: false,
        tableView: false,
        values: [
          // {
          //   label: 'SFA Credit',
          //   value: 'sfaCredit',
          //   shortcut: '',
          // },
          {
            label: 'Bank Transfer',
            value: 'bankTransfer',
            shortcut: '',
          },
        ],
        validate: {
          required: true,
        },
        defaultValue: 'bankTransfer',
        customConditional: 'show = (data.sport_event.split(",")[2] > \'0\')',
        key: 'mode',
        type: 'radio',
        input: true,
      },
      {
        label: 'Whats your reason to ask refund?',
        placeholder: 'Add Your Reason',
        tableView: true,
        key: 'reason',
        validate: {
          required: true,
        },
        type: 'textfield',
        input: true,
      },
    ],
  };

  return (
    <div className={'w-full bg-white rounded-lg p-4 mb-4 md:my-3'}>
      <Modal
        isOpen={showRefundModal}
        onRequestClose={() => setShowRefundModal(false)}
        className="Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
          },
          content: {
            padding: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
        ariaHideApp={false}
      >
        <div className={'bg-gray-90 border-b-2 py-3 px-7 font-bold text-gray-750'}>
          Ask Refund for [{payment.tournament}]
        </div>
        <div className={'p-5 flex-1'}>
          <Form
            form={refundForm}
            ref={formioRef}
            formReady={setForm}
            onSubmit={(submission) => {
              handleSubmission(submission.data);
              setShowRefundModal(false);
            }}
          />
          <div className={'text-xs font-roboto mt-5'}>Note:</div>
          <div className={'text-xs font-roboto'}>
            Your participation in the selected events will be revoked and you’ll know longer be
            added to list. You refund might take 6-7 business days.
          </div>
        </div>
        <div
          className={
            'bg-gray-90 border-t-2 py-3 px-7 font-bold text-gray-750 flex-row flex justify-end'
          }
        >
          <button
            className={'rounded-lg border-2 px-3 py-1 border-gray-500'}
            onClick={() => setShowRefundModal(false)}
          >
            Cancel
          </button>
          <button
            className={'rounded-lg border-2 px-3 py-1 bg-gray-750 text-white border-gray-500 ml-3'}
            onClick={() => {
              formioRef.submit();
            }}
          >
            Submit
          </button>
        </div>
      </Modal>
      <div className={'flex justify-between items-stretch'}>
        <div className={'font-bold font-roboto text-gray-750 text-sm md:text-xl'}>
          {payment.tournament}
        </div>
        <div
          className={'cursor-pointer flex items-center p-2 relative'}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <img src={DropDown} alt={''} />
          {isMenuOpen && (
            <>
              <div
                className={'fixed w-screen h-screen z-1 top-0 left-0'}
                onClick={() => setIsMenuOpen(false)}
              />
              <div
                className={'absolute w-48 bg-white rounded-lg z-2 shadow-lg py-4'}
                style={{
                  top: '50%',
                  right: '100%',
                }}
              >
                <div
                  className={'flex text-xs hover:bg-gray-100 p-2 px-4'}
                  onClick={() => {
                    window.open(
                      `/api/method/sfa_online.v1.registrations.cart.call_download_pdf?invoice=${payment.invoice}`,
                      '_blank'
                    );
                    setIsMenuOpen(!isMenuOpen);
                  }}
                >
                  <img src={DownloadIcon} className={'mr-2 w-5'} alt={''} />
                  Download Receipt
                </div>
                {payment.sport_event.filter((el, id) => el.is_team_sport === 0).length > 0 &&
                !payment.cash_payment &&
                payment?.tournament_status?.toLowerCase() === 'registration' ? (
                  <div
                    className={'flex text-xs hover:bg-gray-100 p-2 px-4'}
                    onClick={() => {
                      setShowRefundModal(true);
                    }}
                  >
                    <img src={BillingIcon} className={'mr-2 w-5'} alt={''} />
                    Ask For Refund
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>

      <div className={'flex justify-between items-center mt-5 mb-2 md:mb-0'}>
        <div className={'text-xs md:text-sm font-roboto text-gray-650'}>Events Participated</div>
        <div className={'font-roboto text-xs md:text-sm text-gray-650'}>
          {new Date(payment.invoice_date).toLocaleString('en-IN', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            // minute: '2-digit',
            // hour: '2-digit',
            // hour12: true,
          })}
        </div>
      </div>

      {payment.participated_event.map((event, i) => {
        return (
          <div key={i} className={'flex justify-between items-center my-2 md:my-0'}>
            <div className={'text-xs md:text-sm font-roboto  text-gray-750 '}>
              {event.item.split('-')[1] === undefined
                ? event.item
                : `${event.item.split('-')[3] ? event.item.split('-')[3] : ''}-${
                    event.item.split('-')[4] ? event.item.split('-')[4] : ''
                  } ${event.item.split('-')[1] ? event.item.split('-')[1] : ''} ${
                    event.item.split('-')[5] ? event.item.split('-')[5] : ''
                  }`}
            </div>
            <div className={' font-roboto  text-gray-750 '}>₹ {event.price}</div>
          </div>
        );
      })}
      <hr style={{ marginLeft: '0', marginRight: '0', marginTop: '20px' }} />
      <div className={'flex justify-between mb-3 items-center'}>
        <div className={'text-xs md:text-sm font-roboto flex items-center font-bold'}>
          <img src={BlackCheckCircle} className={'mr-2'} alt={''} />
          Payment Received
        </div>
        <div className={' font-roboto  font-bold  text-gray-750 flex items-center'}>
          Total: ₹ {payment.total_with_gst}
        </div>
      </div>
      <hr style={{ marginLeft: '0', marginRight: '0', marginTop: '20px' }} />
      {payment.refunded_sport_event.length > 0 ? (
        <div className={'flex justify-between items-center'}>
          <div className={'text-xs md:text-sm font-roboto text-gray-650 my-2 md:my-2'}>
            Refunded Events
          </div>
        </div>
      ) : null}

      {submittedRefund.length !== 0 ? (
        <>
          <hr style={{ marginLeft: '0', marginRight: '0', marginTop: '20px' }} />
          <div className={'flex justify-between items-center'}>
            <div className={'text-sm font-roboto text-gray-650'}>Refund submitted</div>
          </div>
          <div className={'text-sm flex font-roboto  text-gray-750 '}>
            {`${
              submittedRefund[0]?.sport_event.split('-')[3]
                ? submittedRefund[0]?.sport_event.split('-')[3]
                : ''
            }-${
              submittedRefund[0]?.sport_event.split('-')[4]
                ? submittedRefund[0]?.sport_event.split('-')[4]
                : ''
            } ${
              submittedRefund[0]?.sport_event.split('-')[1]
                ? submittedRefund[0]?.sport_event.split('-')[1]
                : ''
            } ${
              submittedRefund[0]?.sport_event.split('-')[5]
                ? submittedRefund[0]?.sport_event.split('-')[5]
                : ''
            }`}
            &nbsp;
            <span
              className={
                'text-gray-400 bg-gray-50 font-bold text-xxs md:text-xs px-1  font-roboto ml-4'
              }
            >
              <FontAwesomeIcon icon={faSpinner} spin={true} /> Your refund has been submitted.
            </span>
          </div>
        </>
      ) : null}

      {payment.refunded_sport_event.map((event, i) => {
        return (
          <div key={i} className={'flex justify-between items-center my-2 md:mt-0 md:mb-1'}>
            <div>
              <div className={'text-xs md:text-sm flex font-roboto items-center text-gray-750'}>
                {`${event.age_group} ${event.sport} ${
                  event.name.split('-')[5] ? event.name.split('-')[5] : ''
                }`}
                &nbsp;
                {event.refund_status === 'Approved' ? (
                  <span
                    className={
                      'text-green-450 bg-green-50 pt-0.5 font-bold font-roboto text-xxs md:text-xs px-1 ml-2'
                    }
                  >
                    <span className="hidden md:inline">✔ Refund Processed</span>
                    <span className="inline md:hidden">✔ Processed</span>
                  </span>
                ) : event.refund_status === 'Waiting Approval' ? (
                  <span
                    className={
                      'text-gray-400 bg-gray-50 font-bold text-xxs md:text-xs px-1  font-roboto ml-2'
                    }
                  >
                    <FontAwesomeIcon icon={faSpinner} spin={true} /> Pending
                  </span>
                ) : event.refund_status === 'Rejected' ? (
                  <span
                    className={
                      'text-red-500 pt-0.5 bg-red-50 font-bold text-xxs md:text-xs font-roboto px-1 ml-2'
                    }
                  >
                    <span className="hidden md:inline">✘ Refund Declined</span>
                    <span className="inline md:hidden">✘ Declined</span>
                  </span>
                ) : null}
              </div>
              {event.refund_msg ? (
                <div className="text-xxs md:text-xs font-bold">
                  Reason:<span className="text-gray-500 font-normal">{event.refund_msg}</span>
                </div>
              ) : null}
            </div>
            <div className={' font-roboto flex justify-end items-end flex-col'}>
              ₹ {event.price}
              {/* <div className={'text-xs font-roboto text-gray-650'}>
                                {new Date(event?.refund_date).toLocaleString('en-IN', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                    // minute: '2-digit',
                                    // hour: '2-digit',
                                    // hour12: true,
                                })}
                            </div> */}
            </div>
          </div>
        );
      })}
      {/* <hr style={{ marginLeft: '0', marginRight: '0', marginTop: '20px' }} />
      <div className={'flex justify-between items-center'}>
        <div className={'text-sm  font-roboto text-gray-750 '}>Credit in SFA Wallet</div>
        <div className={' text-gray-750  font-roboto font-bold'}>
          Total: ₹ {payment.credit_amount}
        </div>
      </div> */}
    </div>
  );
};

export default BillingInformationCard;
