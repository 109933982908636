import { useMutation } from 'react-query';
import {
  postConfirmTeam,
  postCreateTeam,
  postDeleteParticipant,
  postDeleteTeam,
  postTeamSubmitSportForm,
  submitRefundForm,
} from '../services';

export const useMutateCreateTeam = ({ onSuccess, onError, ...params }) => {
  return useMutation((data) => postCreateTeam(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
    ...params,
  });
};

export const useMutateDeleteTeam = ({ onSuccess, onError, onMutate, onSettled }) => {
  return useMutation((data) => postDeleteTeam(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onMutate: async (result) => {
      if (typeof onMutate !== 'undefined') return await onMutate(result);
    },
    onError: async (err, newValue, context) => {
      typeof onError && onError(err, newValue, context);
    },
    onSettled: () => typeof onSettled && onSettled(),
  });
};

export const useMutateDeleteParticipant = ({ onSuccess, onError, onMutate, onSettled }) => {
  return useMutation((data) => postDeleteParticipant(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onMutate: (result) => {
      return typeof onMutate !== 'undefined' && onMutate(result);
    },
    onError: async (err, newValue, context) => {
      typeof onError && onError(err, newValue, context);
    },
    onSettled: () => {
      typeof onSettled !== 'undefined' && onSettled();
    },
  });
};

export const useTeamSubmitSportForm = ({ onSuccess, onError }) => {
  return useMutation((data) => postTeamSubmitSportForm(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};

export const useConfirmTeam = ({ onSuccess, onError }) => {
  return useMutation((data) => postConfirmTeam(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};

export const useMutateRefundSubmission = ({ onSuccess, onError }) => {
  return useMutation((data) => submitRefundForm(data), {
    onSuccess: (result) => {
      typeof onSuccess && onSuccess(result);
    },
    onError: async (err) => {
      typeof onError && onError(err);
    },
  });
};
