import React, { useState } from 'react';
import ProfileBar from '../Components/ProfileBar';
import { useProfileBillingDetails, useUserProfile } from '../queries/profileHooks';
import Loader from '../../components/Loader';
import BillingInformationCard from '../Components/BillingInformationCard';
import { useMutateRefundSubmission } from '../queries/mutations';
import Information from '../../assets/img/Information.svg';

const BillingAndInformation = () => {
  const [selectedTab] = React.useState(0);

  const profileBilling = useProfileBillingDetails();
  const { data } = useUserProfile();
  const userPrimaryRole = data?.message?.primary_role;
  const [submittedRefund, setSubmittedRefund] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // implement the info functionality

  const { mutateAsync: submitRefund } = useMutateRefundSubmission({
    onSuccess: async (d) => {
      await profileBilling.refetch();
      setSubmittedRefund([]);
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const handleSubmission = async (submission) => {
    setSubmittedRefund([
      {
        sport_event: submission.sport_event.split(',')[1],
      },
    ]);
    await submitRefund({
      sport_event: submission.sport_event.split(',')[1],
      invoice: submission.sport_event.split(',')[0],
      mode: submission.mode,
      refund_message: submission.reason,
      credit_refund: submission.sport_event.split(',')[3],
      bank_refund: submission.sport_event.split(',')[2],
    });
  };

  return (
    <>
      <ProfileBar
        head={
          <div>
            Billing & Information
            {userPrimaryRole === 'athlete' && (
              <img
                className="cursor-pointer w-7 h-7 mr-4 inline-block"
                alt="info icon"
                src={Information}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPopup(!showPopup);
                }}
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  setShowPopup(true);
                }}
                onMouseLeave={() => setShowPopup(false)}
              />
            )}
          </div>
        }
      />
      {showPopup ? (
        <div className="absolute border-1 border-gray-150 md:-mt-8 -mt-4 ml-12 md:ml-48 z-20 w-64 text-sm shadow-cartPaymentPopUp bg-white rounded px-4 py-6">
          <div className="shadow-shadowBottom text-xs pb-2">
            To WITHDRAW your participation from any TEAM SPORT EVENT, kindly contact your school
            team’s coach or contact us regarding the same at{' '}
            <a
              target="_blank"
              href="mailto:UK.support@sfaplay.com?subject=SFA Uttarakhand Championship 2022 - Refund Request&body=Please add your refund query here"
              rel="noreferrer"
            >
              UK.support@sfaplay.com
            </a>
          </div>
        </div>
      ) : null}
      <div className={'w-full flex flex-col items-center'}>
        <div className={'w-full md:w-11/12'}>
          {selectedTab === 0 ? (
            <div>
              {profileBilling.isLoading ? (
                <Loader />
              ) : (
                profileBilling?.data?.map((payment, i) => {
                  return (
                    <BillingInformationCard
                      payment={payment}
                      key={i}
                      handleSubmission={handleSubmission}
                      submittedRefund={submittedRefund}
                      isLoading={profileBilling?.isLoading}
                    />
                  );
                })
              )}
            </div>
          ) : (
            <div>Saved Cards</div>
          )}
        </div>
      </div>
    </>
  );
};

export default BillingAndInformation;
